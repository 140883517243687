<header class="header--progress">
    <div class="c-banner">
        <h1 class="c-heading u-flex-col c-banner__content u-mtop-ism">
            <span>{{salutation}}, <span class="data-hj-suppress"> {{case.client.forename}} {{case.client.surname}}</span></span>
            <span class="c-heading c-heading-light" id="case-reference">Case: {{case.reference}}</span>
            <span class="c-heading c-heading-green c-heading-uppercase" *ngIf="isSettled"><font class="vertical-align"><font class="vertical-align">Claim settled</font></font></span>
        </h1>
    </div>
    <div class="c-progress-summary" *ngIf="milestones?.length > 0 && !case.readOnly">
        <div class="c-progress-summary__heading">
            <h2 class="c-subheading--md">Claim progress</h2>
            <button *ngIf="milestones.length > 0" id="button-timeline" class="c-progress-summary__heading__btn c-text-link" (click)="onViewTimeline()">View timeline <i class="fas fa-chevron-right u-show-desktop"></i></button>
        </div>

        <ul class="c-progress-summary__steps" *ngIf="milestones.length > 0">
            <li class="c-progress-summary__step c-progress-summary__step--complete">
                <div class="c-progress-summary__step__info">
                    <svg class="c-progress-summary__step__ic c-ic"><use xlink:href="#ic-status-check" href="#ic-status-check"></use></svg>
                    <span class="c-progress-summary__step__label">{{previousStep.name}}</span>
                </div>
            </li>
            <li class="c-progress-summary__step" [ngClass]="{'c-progress-summary__step--active': !isCompleted && !isAheadEmpty, 'c-progress-summary__step--complete': isCompleted || isAheadEmpty}">
                <div class="c-progress-summary__step__info">
                    <svg class="c-progress-summary__step__ic c-ic" *ngIf="!isCompleted && !isAheadEmpty"><use xlink:href="#ic-status-marker" href="#ic-status-marker"></use></svg>
                    <svg class="c-progress-summary__step__ic c-ic" *ngIf="isCompleted || isAheadEmpty"><use xlink:href="#ic-status-check" href="#ic-status-check"></use></svg>
                    <span class="c-progress-summary__step__label">{{currentStep.name}}</span>
                </div>
            </li>
            <li class="c-progress-summary__step" [ngClass]="{'c-progress-summary__step--incomplete c-progress-summary__step--dotted': !isCompleted && !isAheadEmpty, 'c-progress-summary__step--active': isCompleted || isAheadEmpty}">
                <div class="c-progress-summary__step__info">
                    <svg class="c-progress-summary__step__ic c-ic" *ngIf="!isCompleted && !isAheadEmpty"><use xlink:href="#ic-status-ellipsis" href="#ic-status-ellipsis"></use></svg>
                    <svg class="c-progress-summary__step__ic c-ic" *ngIf="isAheadEmpty"><use xlink:href="#ic-status-marker" href="#ic-status-marker"></use></svg>
                    <svg class="c-progress-summary__step__ic c-ic" *ngIf="isCompleted"><use xlink:href="#ic-status-check" href="#ic-status-check"></use></svg>
                    <span class="c-progress-summary__step__label">{{nextStep.name}}</span>
                </div>
            </li>
        </ul>
    </div>
</header>

<section>
    <div class="c-tab-nav u-mtop-ism">
        <ul class="c-tab-nav__list" aria-label="Claim navigation" role="navigation">
            <li class="c-tab-nav__item">
                <button role="tab" routerLinkAriaActive routerLinkActive="c-tab-nav__button--active" class="c-tab-nav__button" routerLink="./details" id="claim-button" disableTabOnModal>
                    <span class="c-tab-nav__button__text">Claim</span>
                    <span class="c-tab-nav__button__ic">
                        <svg class="c-ic c-ic-vertical c-tab-nav__button__icon">
                            <use xlink:href="#ic-detail" href="#ic-detail"></use>
                        </svg>
                    </span>
                </button>
            </li>
            <li class="c-tab-nav__item">
                <button role="tab" routerLinkAriaActive routerLinkActive="c-tab-nav__button--active" class="c-tab-nav__button" routerLink="./losses" id="loss-button" disableTabOnModal>
                    <span class="c-tab-nav__button__text">Losses</span> <span class="c-tab-nav__button__ic">
                        <svg class="c-ic c-ic--sm c-ic-vertical c-tab-nav__button__icon">
                            <use xlink:href="#ic-losses" href="#ic-losses"></use>
                        </svg>
                    </span>
                </button>
            </li>
            <li class="c-tab-nav__item">
                <button role="tab" routerLinkAriaActive routerLinkActive="c-tab-nav__button--active" class="c-tab-nav__button" routerLink="./medical_oic" id="medical-button" disableTabOnModal>
                    <span class="c-tab-nav__button__text">Medical</span> <span class="c-tab-nav__button__ic">
                        <svg class="c-ic c-ic--sm c-ic-vertical c-tab-nav__button__icon">
                            <use xlink:href="#ic-medical" href="#ic-medical"></use>
                        </svg>
                    </span>
                </button>
            </li>
            <li class="c-tab-nav__item" *ngIf="isAtNegotiationAndOic">
                <button role="tab" routerLinkAriaActive routerLinkActive="c-tab-nav__button--active" class="c-tab-nav__button" routerLink="./negotiations" id="medical-button" disableTabOnModal>
                    <span class="c-tab-nav__button__text">Negotiations</span> <span class="c-tab-nav__button__ic">
                        <svg class="c-ic c-ic--sm c-ic-vertical c-tab-nav__button__icon">
                            <use xlink:href="#ic-negotiations" href="#ic-negotiations"></use>
                        </svg>
                    </span>
                </button>
            </li>
            <li class="c-tab-nav__item" *ngIf="isSettled">
                <button role="tab" routerLinkAriaActive routerLinkActive="c-tab-nav__button--active" class="c-tab-nav__button" routerLink="./settlement" id="settlement-button" disableTabOnModal>
                    <span class="c-tab-nav__button__text">Settlements</span>
                    <span class="c-tab-nav__button__ic">
                        <svg class="c-ic c-ic--sm c-ic-vertical c-tab-nav__button__icon">
                            <use xlink:href="#ic-settlements" href="#ic-settlements"></use>
                        </svg>
                    </span>
                </button>
            </li>
        </ul>
    </div>
    <router-outlet #route="outlet"></router-outlet>
</section>
<app-claim-progress [case]="case" [milestones]="milestones" *ngIf="milestones?.length > 0 && !case.readOnly"></app-claim-progress>
