<div class="c-modal display-block" role="dialog" *ngIf="showInterrupt">
    <div class="c-modal__content">

        <div class="c-modal-top-nav">
            <div class="c-modal-top-nav__logo">
                <svg class="c-ic-purple c-modal-top-nav__logo-img">
                    <use xlink:href="#ic-logo-2" href="#ic-logo-2"></use>
                </svg>
            </div>
            <button class="c-ic-button c-modal-top-nav__close" role="button" aria-label="close" (click)="close()" id="dismiss-ink-modal-button">
                <svg class="c-ic c-ic--sm">
                    <use xlink:href="#ic-close" href="#ic-close"></use>
                </svg>
            </button>
        </div>

        <div class="c-mia-modal">
            <div class="mia-modal-img">
                <img class="c-banner__mia c-banner__mia--modal c-mia-img recite-ignore-styles-background" alt="Minster law logo" src="/images/Mia.svg">
            </div>
        </div>

        <div class="c-modal__content__body c-mia-modal-body">
            <div *ngIf="showNewPortal" id="interrupt-new-portal">
                <p class="c-text-width c-text-dark">Minster Claims Online has a new identity and new features. Welcome to {{portalName}}.</p>
                <p class="c-text-width c-text-dark">We kept all your favourite features and further improved them to help you to better manage your personal injury claim.</p>
                <p class="c-text-width c-text-dark">Don't worry, all of your claim information can still be found in your account. Log in using your usual email and password and take a quick tour of your new portal.</p>
            </div>
        </div>
    </div>
</div>
