<ng-container *ngIf="!completed; else isCompleted">
    <h1 class="c-heading u-mtop-mobile-md">Forgotten your password?</h1>
    <p class="c-text-width">Please enter your email to reset your password.</p>
    <form [formGroup]="form" (submit)="submit(form)" autocomplete="off" novalidate>
        <div class="c-text-input u-mtop-ism">
            <label for="username" class="u-text-bold c-text-white">Email</label>
            <input class="c-text-input-style" type="text" placeholder="Email" name="username" id="username" [formControl]="form.controls['username']" autocomplete="off" autofocus [ngClass]="{'is-danger' : submitted && form.controls.username.hasError('required')}">
            <ng-container *ngIf="submitted">
                <minster-validation-message *ngIf="form.controls.username.hasError('required')" message="Please enter your email address"></minster-validation-message>
                <minster-validation-message *ngIf="form.controls.username.hasError('email')" message="Please enter a valid email address"></minster-validation-message>
            </ng-container>
        </div>

        <minster-validation [messages]="errors"></minster-validation>
        <minster-button buttonType="submit" [disabled]="submitting" id="logInButton" text="Submit" isPrimary="true" [isLoading]="submitting ? true : false"></minster-button>
        <div class="u-mtop-ism">
            <minster-link underline="true" [mlRouterLink]="['/auth/logout']" [disabled]="submitting" text="Back to login"></minster-link>
        </div>
    </form>
</ng-container>
<ng-template #isCompleted>
    <h1 class="c-heading u-mtop-mobile-md">Reset requested</h1>
    <p class="c-text-width"><strong>If you have an INK account your password reset email is on its way. If you didn't complete your INK account registration at the start of your claim you'll be sent a new invite.</strong></p>
    <p class="c-text-width">You should receive your email within 10 minutes. However, if you haven't you should;</p>
    <ul>
        <li>Check the email you entered is correct.</li>
        <li>Check your junk folder</li>
        <li>Check if your inbox is full</li>
        <li>Contact the team for further support. You can find their contact details on our last communication with you.</li>
    </ul>
    <div class="u-mtop-ism">
        <minster-link underline="true" [mlRouterLink]="['/auth/logout']" [disabled]="submitting" text="Back to login"></minster-link>
    </div>
</ng-template>
