<!-- SLIDING PANEL -->
<div class="c-modal display-block" *ngIf="isPanelOpen">
    <div class="c-panel-slide-out c-panel-slide-out--right" [ngClass]="{'c-panel-slide-out--open': isPanelOpen}" role="dialog" id="mobile-close-container">
        <ng-container *ngIf="isTimeLine; else isDescription">
            <!-- PROGRESS TIMELINE -->
            <header class="c-panel-slide-out__header">
                <div class="c-panel-slide-out__header__logo-bar">
                    <svg class="c-panel-slide-out__header__logo c-ic-purple">
                        <use xlink:href="#ic-logo-2" href="#ic-logo-2"></use>
                    </svg>
                </div>
                <div class="c-panel-slide-out__btns c-panel-slide-out__btns--right">
                    <button class="c-panel-slide-out__btn c-panel-slide-out__close" aria-label="Close claim progress timeline" (click)="onCancelTimeLine()"><svg class="c-ic c-ic--sm"> <use xlink:href="#ic-close" href="#ic-close"></use></svg></button>
                </div>
                <h2 class="c-panel-slide-out__heading c-subheading--md">Claim progress timeline</h2>
            </header>
            <ul class="c-timeline">
                <li [id]="'milestone-' + i" class="c-timeline__item c-timeline__item--complete" *ngFor="let completeMilestone of completedMilestones; let i = index;">
                    <svg class="c-timeline__item__ic c-ic"><use xlink:href="#ic-status-check" href="#ic-status-check"></use></svg>
                    <span class="c-timeline__item__label">
                        {{completeMilestone.name}} <button aria-label="What does claim registered mean?" class="c-btn--info" (click)="onInfoClick(completeMilestone)"><i class="fas fa-info-circle"></i></button>
                        <span class="c-timeline__item__status">Complete</span>
                    </span>
                </li>

                <li id="current-milestone" class="c-timeline__item c-timeline__item--highlight c-timeline__item c-timeline__item--incomplete c-timeline__item--card" *ngIf="!isClaimProgressCompleted">
                    <svg class="c-timeline__item__ic c-ic"><use xlink:href="#ic-medical-report" href="#ic-medical-report"></use></svg>
                    <span class="c-timeline__item__label">{{currentMilestone.name}} <span class="c-timeline__item__status">{{currentMilestone.shortDescription}}</span></span>
                    <button aria-label="What is a medical report?" class="c-btn--info" (click)="onInfoClick(currentMilestone)"><i class="fas fa-info-circle"></i></button>
                </li>

                <li class="c-timeline__item c-timeline__item--incomplete" *ngIf="!isAheadMilestonesEmpty">
                    <svg class="c-timeline__item__ic c-ic"><use xlink:href="#ic-double-chevron-down" href="#ic-double-chevron-down"></use></svg>
                    <span class="c-timeline__item__label">What's ahead...</span>
                </li>

                <li class="c-timeline__item c-timeline__item--incomplete" *ngFor="let aheadMilestone of aheadMilestones">
                    <svg class="c-timeline__item__ic c-ic"><use xlink:href="#ic-status-ellipsis" href="#ic-status-ellipsis"></use></svg>
                    <span class="c-timeline__item__label">
                        {{aheadMilestone.name}} <button aria-label="What is an injury valuation?" class="c-btn--info" (click)="onInfoClick(aheadMilestone)"><i class="fas fa-info-circle"></i></button>
                        <span class="c-timeline__item__status">Pending</span>
                    </span>
                </li>
            </ul>
        </ng-container>
        <ng-template #isDescription>
            <!-- PHRASE EXPLANATION DESIGN -->
            <header class="c-panel-slide-out__header">
                <div class="c-panel-slide-out__header__logo-bar">
                    <svg class="c-panel-slide-out__header__logo c-ic-purple">
                        <use xlink:href="#ic-logo-2" href="#ic-logo-2"></use>
                    </svg>
                </div>
                <div class="c-panel-slide-out__btns">
                    <button class="c-panel-slide-out__btn c-panel-slide-out__back" aria-label="Back" (click)="onBackDescription()"><svg class="c-ic c-ic--sm"> <use xlink:href="#ic-arrow-left" href="#ic-arrow-left"></use></svg><span class="c-btn__label">Back</span></button>
                    <button class="c-panel-slide-out__btn c-panel-slide-out__close" aria-label="Close claim progress timeline" (click)="onCancelTimeLine()"><svg class="c-ic c-ic--sm"> <use xlink:href="#ic-close" href="#ic-close"></use></svg></button>
                </div>
                <div class="c-panel-slide-out__heading-wrapper">
                    <button class="c-button c-button--circle" aria-label="Previous" (click)="onPreviousMilestoneDescription()" [ngClass]="{'c-button--disabled': detailsIndex == 0}"><i class="fas fa-chevron-left"></i></button>
                    <h2 class="c-panel-slide-out__heading c-subheading--md">{{milestone.name}}</h2>
                    <button class="c-button c-button--circle" aria-label="Next" (click)="onNextMilestoneDescription()" [ngClass]="{'c-button--disabled': detailsIndex == milestones.length - 1}"><i class="fas fa-chevron-right"></i></button>
                </div>
            </header>
            <div class="c-panel-slide-out__subheading-wrapper">
                <svg *ngIf="milestone == currentMilestone" class="c-ic"><use xlink:href="#ic-status-marker-no-border" href="#ic-status-marker-no-border"></use></svg>
                <svg *ngIf="!milestone.completed && milestone != currentMilestone" class="c-ic"><use xlink:href="#ic-status-ellipsis-no-stroke" href="#ic-status-ellipsis-no-stroke"></use></svg>
                <svg *ngIf="milestone.completed && milestone != currentMilestone" class="c-ic"><use xlink:href="#ic-status-check-no-stroke" href="#ic-status-check-no-stroke"></use></svg>
                <h3 class="c-panel-slide-out__heading c-subheading--md c-heading" [ngClass]="getStatusClass(milestone)">{{getStatusText(milestone)}}</h3>
            </div>
            <p [innerHTML]="milestone.description"></p>
            <div class="u-text--center" *ngFor="let internalLink of milestone.internalLinks"><button class="c-button c-button--long" (click)="onViewInternalLink(internalLink)">{{internalLink.linkText}}</button></div>
            <div class="u-text--center" *ngFor="let externalUrl of milestone.externalLinks"><a class="c-button c-button--long c-button--link" [href]="externalUrl" target="_blank">View external link</a></div>
        </ng-template>
    </div>
</div>
