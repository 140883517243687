<div class="c-modal display-block" role="dialog">
    <div class="c-modal__content">
        <div class="c-modal__content__body">

            <p class="c-text-width c-text-dark">Minster Law uses cookies to provide you with a better experience. By accepting all cookies, you agree to our use of cookies to deliver and maintain our site, improve the quality and understand issues and faults.</p>
            <p class="c-text-width c-text-dark">By rejecting non-essential cookies, Minster Law may still use certain cookies to ensure the proper functionality of our platform. For more information, please see our
                <a target="_blank" href="https://www.minsterlaw.co.uk/cookie-policy/">Cookie Notice</a>  and our <a target="_blank" href="https://www.minsterlaw.co.uk/privacy-policy/">Privacy Policy.</a> </p>

            <div class="c-button-stack-mobile u-mtop-sm">
                <button class="c-button c-button--grey c-button--long" (click)="onDecline()">Reject Non-essential</button>
                <button class="c-button c-button--long" (click)="onAccept()">Accept all</button>
            </div>
        </div>
    </div>
</div>

