import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Observable, Subscription, from } from 'rxjs';
import { WelcomePackDocumentModel, DocumentModel, PortalRequestModel, DocumentUploadRequestModel, DocumentUploadModel, DocumentUploadResponseModel } from '../models/document.model';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthService } from './auth.service';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { formatDate } from '@angular/common';
import { CaseModel } from '../models/case.model';
import { AppInsightsService } from './appinsights.service';

export interface AddDocumentRequest {
    case: CaseModel;
}

export enum DocumentName {
    WelcomePack = 'WelcomePack',
    _0CFA = '0CFA',
    _25CFA = '25CFA',
    TermsAndConditions = 'TermsAndConditions',
    KeyInformation = 'KeyInformation',
    KeyInformationv1 = 'KeyInformationv1',
    KeyInformationv2 = 'KeyInformationv2',
    KeyInformationv3 = 'KeyInformationv3',
    KeyInformationv4 = 'KeyInformationv4',
    TermsAndConditionsv1 = 'TermsAndConditionsv1',
    TermsAndConditionsv2 = 'TermsAndConditionsv2',
    _OCFAv1 = 'OCFAv1',
    _25CFAv1 = '25CFAv1'

}

@Injectable({
    providedIn: 'root'
})
export class DocumentService implements OnDestroy, Resolve<DocumentModel[]> {

    form: UntypedFormGroup;
    documentUploadList: DocumentUploadModel[] = [];
    authSubscription: Subscription;
    documentTypes: any;

    @Output() addDocumentStarted: EventEmitter<AddDocumentRequest> = new EventEmitter<AddDocumentRequest>();
    @Output() documentsUploaded: EventEmitter<string> = new EventEmitter<string>();
    @Output() addDocumentCancelled: EventEmitter<AddDocumentRequest> = new EventEmitter<AddDocumentRequest>();
    @Output() addDocumentCommitted: EventEmitter<AddDocumentRequest> = new EventEmitter<AddDocumentRequest>();

    constructor(
        private http: HttpClient,
        private fb: UntypedFormBuilder,
        private authService: AuthService,
        private appInsights: AppInsightsService,
        @Inject('API_BASE_URL') private baseUrl: string
    ) {
        this.setForm();
        this.authService.isLoggingOut.subscribe(() => {
            this.documentUploadList = [];
            this.setForm();
        });

        this.documentTypes = [
            { name: 'losses', display: 'Losses' },
            { name: 'liabilityEvidence', display: 'Liability evidence' },
            { name: 'court', display: 'Court' },
            { name: 'medical', display: 'Medical' },
            { name: 'settlement', display: 'Settlement' }
        ];
    }

    startAddDocument(_case: CaseModel) {
        this.appInsights.logComplexEvent('Add loss started', { case: _case.reference });
        this.addDocumentStarted.emit({ case: _case});
    }

    resolve(route: ActivatedRouteSnapshot): DocumentModel[] | Observable<DocumentModel[]> | Promise<DocumentModel[]> {
        return this.get(route.params.reference);
    }

    setForm() {
        this.form = this.fb.group({
            'name': [{ value: '', disabled: false }, Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(100), Validators.pattern(/^[a-zA-Z0-9 ]+$/)])],
            'type': [{ value: '', disabled: false }, Validators.required]
        });
    }

    get(caseReference: string): Observable<DocumentModel[]> {
        const url = this.baseUrl + 'document/getdocuments/' + caseReference;
        return this.http.get(url, {}) as Observable<DocumentModel[]>;
    }

    getDocWelcomePack(caseReference: string): Observable<WelcomePackDocumentModel[]> {
        const url = this.baseUrl + 'document/getdocwelcomepack/' + caseReference;
        return this.http.post(url, {}) as Observable<WelcomePackDocumentModel[]>;
    }

    download(guid: string, caseReference: string): Observable<WelcomePackDocumentModel> {
        const url = this.baseUrl + 'document/download/' + guid + '/' + caseReference;
        return this.http.get(url) as Observable<WelcomePackDocumentModel>;
    }

    decode(base64String: string): Observable<any> {
        const url = this.baseUrl + 'decode';
        return this.http.post(url, { 'base64': base64String });
    }

    content(model: DocumentModel): Observable<any> {
        const url = this.baseUrl + 'content';
        return this.http.post(url, { group: model.group, documentId: model.id });
    }

    contentOld(contentUrl: string): Observable<any> {
        const url = this.baseUrl + 'contentOld';
        return this.http.post(url, { contentUrl: contentUrl });
    }

    approve(model: PortalRequestModel): Observable<DocumentModel> {
        const url = this.baseUrl + 'document/approve';
        return this.http.post(url, model) as Observable<DocumentModel>;
    }

    reject(requestModel: PortalRequestModel): Observable<DocumentModel> {
        const url = this.baseUrl + 'document/reject';
        return this.http.post(url, requestModel) as Observable<DocumentModel>;
    }

    upload(caseReference: string, documentType: string, documentName: string, formData: FormData): Observable<string> {

        const url = this.baseUrl + 'multipart/document/clientUpload/' + caseReference + '/' + documentType + '/' + documentName;

        const promise = new Promise<string>((resolve, reject) => {
            this.http.post(url, formData, { responseType: 'text'}).subscribe(
                result => {
                    let resultNoQuotes = result.toString().replace(/^"(.*)"$/, '$1');
                    this.documentsUploaded.emit(resultNoQuotes);
                    resolve(resultNoQuotes);
                },
                error => {
                    reject(error);
                });
        });

        return from(promise);
    }

    commit(groupId: string, _case: CaseModel, lossType: string = null, subLossType: string = null, lossIncurred: Date = null, lossAmount: number = null, notes: string = null): Observable<boolean> {

        const promise = new Promise<boolean>((resolve, reject) => {

            const url = this.baseUrl + 'document/commitFileUpload';

            this.http.post(url, {
                caseReference: _case.reference,
                groupId,
                lossType,
                subLossType,
                lossIncurred,
                lossAmount,
                notes
            }).subscribe(() => {
                this.addDocumentCommitted.emit({ case: _case});
                resolve(true);
            }, error => {
                reject(error);
            });
        });

        return from(promise);
    }

    cancel(_case: CaseModel): void {
        this.addDocumentCancelled.emit({ case: _case });
    }

    getDocumentTypes() {
        return this.documentTypes;
    }

    getDocumentDisplayName(name: string) {
        const matchedType = this.documentTypes.find(x => x.name.toUpperCase() == name.toUpperCase());
        return matchedType ? matchedType.display : name;
    }

    ngOnDestroy(): void {
        this.authSubscription.unsubscribe();
    }
}
