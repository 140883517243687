import { Injectable, Output, EventEmitter } from '@angular/core';
import { CaseModel } from '../models/case.model';
import { DocumentModel, DocumentUploadRequestModel } from '../models/document.model';
import { DocumentService } from './document.service';
import { AppInsightsService } from './appinsights.service';
import { from, Observable } from 'rxjs';

export interface AddLossRequest {
    case: CaseModel;
}

@Injectable({
  providedIn: 'root'
})
export class LossLegacyService {

    @Output() addLossStarted: EventEmitter<AddLossRequest> = new EventEmitter<AddLossRequest>();
    @Output() documentsUploaded: EventEmitter<string> = new EventEmitter<string>();
    @Output() addLossCancelled: EventEmitter<AddLossRequest> = new EventEmitter<AddLossRequest>();
    @Output() addLossCommitted: EventEmitter<AddLossRequest> = new EventEmitter<AddLossRequest>();

    constructor(private documentService: DocumentService, private appInsights: AppInsightsService) { }

    startAddLoss(_case: CaseModel) {
        this.appInsights.logComplexEvent("Add loss started", { case: _case.reference });
        this.addLossStarted.emit({ case: _case });
    }

    upload(_case: CaseModel, formData: FormData, documentName: string = null): Observable<string> {

        if (!documentName) {
            const now = new Date();
            documentName = `LOSS-${now.getFullYear()}-${now.getMonth()}-${now.getDate()}-${now.getHours()}-${now.getMinutes()}`;
        }

        const promise = new Promise<string>((resolve, reject) => {
            this.documentService.upload(_case.reference, "losses", documentName, formData).subscribe(
                result => {
                    resolve(result);
                    this.documentsUploaded.emit(result);
                },
                error => {
                    reject(error);
                })
        });

        return from(promise);
    }

    commit(_case: CaseModel, groupId: string, lossType: string, subLossType: string, lossIncurred: Date, lossAmount: number, notes: string): Observable<boolean> {
        const promise = new Promise<boolean>((resolve, reject) => {
            this.documentService.commit(groupId, _case, lossType, subLossType, lossIncurred, lossAmount, notes).subscribe(
                () => {
                    resolve(true);
                    this.addLossCommitted.emit({ case: _case });
                },
                error => {
                    reject(error);
                })
        });

        return from(promise);
    }

    cancel(_case: CaseModel): void {
        this.addLossCancelled.emit({ case: _case });
    }
}
