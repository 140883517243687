<app-cookie-modal (hideModal)="showCookieModal=false" *ngIf="showCookieModal"></app-cookie-modal>
<router-outlet *ngIf="!offLine"></router-outlet>
<div *ngIf="offLine" class="c-page-container">
    <div>
        <img alt="Minster law logo" src="/images/logo2.svg" class="c-main-nav__logo">
    </div>
    <h2 class="c-white">INK is offline for some essential maintenance</h2>
    <p>We're sorry INK is down right now for scheduled maintenance. We'll be back soon, so please check again later.</p>
    <img alt="Minster law logo" src="/images/Maintenance.png" class="maintenance image">
</div>

