import { Component, Input, OnInit } from '@angular/core';
import { CaseModel } from 'src/app/models/case.model';
import { AppUtilities } from 'src/app/app.utility';
import { Router } from '@angular/router';

@Component({
    selector: 'personal-details',
    templateUrl: './personal-details.component.html',
    styleUrls: ['./personal-details.component.scss']
})
export class PersonalDetailsComponent implements OnInit {

    @Input() case: CaseModel;
    hasLitigationFriend: boolean = false;

    constructor(private router: Router) {}

    toString = AppUtilities.objectValuesToString;

    ngOnInit() {
        window.scrollTo(0, 0);

        this.hasLitigationFriend = this.case.litigationFriend.dateOfBirth !== '0001-01-01T00:00:00Z' && this.case.litigationFriend.dateOfBirth !== '0001-01-01T00:00:00';
    }

    changeEmail() {
        this.router.navigate(['/portal', this.case.reference, 'change-email-send']);
    }
}
