<div class="l-main-view__content-panel__container l-container-fluid">
    <div class="row ie-fix">

        <ng-container *ngIf="!completed; else isCompleted">
            <h1 class="heading">Change your mail?</h1>
            <p class="c-text-width">To change you email address, select the yes button and we will send an email to your original email address with some instructions on how to change your email.</p>

            <div>
                <minster-button id="change-email-yes" (clicked)="onChangeEmail()" buttonType="button" [disabled]="submitting" [text]="submitting ? 'Submitting...' : 'Yes'" isLarge="true"></minster-button>&nbsp;
                <minster-button id="change-email-no" (clicked)="onReturn()" buttonType="button" [disabled]="submitting" [text]="'No'" isLarge="true"></minster-button>
            </div>

        </ng-container>

        <ng-template #isCompleted>
            <div class="col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12 h-text--left-sm" id="confirm-email-sent">
                <p class="h-font-size--md h-text--center"><strong>We have sent you an email to {{email}}. Please go to your inbox and follow the instructions to change your email.</strong></p>
                <p class="c-text-width">You should receive your email within 10 minutes. If you don't:</p>
                <ul class="text-align-left">
                    <li>Check the email you provided is correct, and the one you used to register on the portal. If it is not, please contact Minster Law</li>
                    <li>Check your junk email folder</li>
                    <li>Please contact our technical support line on
                        <a href="tel:{{contact.telephone}}" class="c-text-link c-text-link--white underline"><span class="h-focus__content" tabindex="-1">{{contact.telephone}}</span></a>
                        for further assistance.</li>
                </ul>
            </div>
        </ng-template>
    </div>
</div>


