<ng-container *ngIf="futureItems && futureItems.length > 0; else noAppointments">
    <div class="m-box__wrapper m-box-wrapper--half">
        <div class="m-box m-box--tabbed" *ngFor="let item of futureItems; let i = index" [attr.id]="'row_' + i">
            <div class="m-box__tab m-box__tab--medical"><svg class="m-ic m-ic--sm"><use xlink:href="#ic-future" href="#ic-future"></use></svg></div>
            <div class="m-box__inner">
                <span class="h-block h-text-weight--bold h-margin-bottom appointment-date-time">
                    <svg class="m-ic m-ic--xs m-ic--inline-block">
                        <use xlink:href="#ic-clock" href="#ic-clock"></use>
                    </svg>
                    {{item.appointmentDateTime | date: 'dd MMMM yyyy, h:mm a' :'UTC'}}
                </span>
                <div class="m-box__inner__flex">
                    <dl class="m-box__inner__flex__item m-description-list h-font-size--sm">
                        <dt class="m-description-list__title">Expert name</dt>
                        <dd class="m-description-list__description">
                            <span class="appointment-expert-title">{{item.expertTitle}}</span>&nbsp;
                            <span class="appointment-expert-name">{{item.expertSurname}}</span>
                        </dd>
                        <dt class="m-description-list__title">Location</dt>
                        <dd class="m-description-list__description appointment-address">
                            {{item.addressLine1}}<br *ngIf="item.addressLine1">
                            {{item.addressLine2}}<br *ngIf="item.addressLine2">
                            {{item.addressLine3}}<br *ngIf="item.addressLine3">
                            {{item.addressLine4}}<br *ngIf="item.addressLine4">
                            {{item.addressLine5}}<br *ngIf="item.addressLine5">
                            {{item.postcode}}
                        </dd>
                    </dl>
                    <div class="m-box__inner__flex__item m-box__img background-image"></div>
                </div>
            </div>
            <!--<button class="m-box__btn h-focus-element"><span class="m-box__btn__label h-focus__content" tabindex="-1">Add to my calendar</span></button>--><!--TODO-->
        </div>
    </div>
</ng-container>
<ng-template #noAppointments>
    <p>We do not hold any appointment information.  If you think this is incorrect, please give your medical agency a call and they will be able to confirm your up to date appointment status.</p>
</ng-template>
