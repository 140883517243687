<h1 class="c-heading u-mtop-mobile-md">Welcome to Ink</h1>
<p class="c-text-width">To log in please enter your email and password.</p>
<form [formGroup]="form" (submit)="submit(form)" (input)="clear()" autocomplete="off" novalidate>
    <div class="c-text-input u-mtop-ism">
        <label for="username" class="u-text-bold c-text-white">Email</label>
        <input class="c-text-input-style" type="text" placeholder="Email" name="username" id="username" [formControl]="form.controls['username']" autocomplete="off" autofocus [ngClass]="{'is-danger' : submitted && form.controls.username.hasError('required')}">
        <ng-container *ngIf="submitted">
            <minster-validation-message *ngIf="form.controls.username.hasError('required')" message="Please enter your email address"></minster-validation-message>
            <minster-validation-message *ngIf="form.controls.username.hasError('email')" message="Please enter a valid email address"></minster-validation-message>
        </ng-container>
    </div>

    <div class="c-text-input u-mtop-ism">
        <div class="c-text-input__top-text">
            <label for="password" class="u-text-bold c-text-white">Password</label>
            <show-hide-password-button [target]="password"></show-hide-password-button>
        </div>
        <input #password class="c-text-input-style" id="password" placeholder="Password" name="password" type="password" [formControl]="form.controls['password']" autocomplete="off" [ngClass]="{'is-danger' : submitted && form.controls.password.hasError('required')}">
        <ng-container *ngIf="submitted">
            <minster-validation-message *ngIf="form.controls.password.hasError('required')" message="Please enter your password"></minster-validation-message>
        </ng-container>
    </div>

    <minster-validation [messages]="errors" id="login-error"></minster-validation>
    <interrupt-login></interrupt-login>
    <div class="u-mtop-ism">
        <minster-link underline="true" [mlRouterLink]="['../forgotten-password']" [disabled]="submitting" text="Forgotten your password?"></minster-link>
        <a class="float-right c-text-link underline c-text-link--white float-right-internal" target="_blank" href="https://www.minsterlaw.co.uk/signin-2/">Need help?</a>
    </div>
    <minster-button buttonClass="u-mtop-ism c-button--link c-button c-button--full c-button--full-mobile c-button--full-always" buttonType="submit" [disabled]="submitting" id="logInButton" ariaLabel="login" text="Log in" submittingText="Logging in..." isPrimary="true" [isLoading]="submitting ? true : false"></minster-button>
</form>
