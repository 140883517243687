import { Component, OnInit, Input } from '@angular/core';
import { CaseModel } from '../../models/case.model';
import types from '../../data/loss-types.json';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { DocumentUploadModel, DocumentUploadRequestModel } from '../../models/document.model';
import { AppUtilities } from '../../app.utility';
import { AppInsightsService } from '../../services/appinsights.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalA11yService } from '../../services/modal-a11y.service';
import { LossLegacyService, AddLossRequest } from 'src/app/services/loss-legacy.service';

const TOTAL_UPLOAD_LIMIT = 30;
const MAX_FILES = Infinity;
const MAX_FILE_SIZE = 1048576 * 10;
const MAX_FILE_SIZE_TOTAL = 1048576 * TOTAL_UPLOAD_LIMIT;

enum DocumentMimeType {
    BMP = 'image/bmp',
    DOC = 'application/msword',
    DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    JPG = 'image/jpg',
    JPEG = 'image/jpg',
    PDF = 'application/pdf',
    PNG = 'image/png',
    TIF = 'font/ttf',
    TIFF = 'font/ttf',
    TXT = 'text/plain',
    XLS = 'application/vnd.ms-excel',
    XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    HEIC = 'image/heic'
}

interface LossType {
    name: string;
    icon: string;
    subTypes: SubLossType[];
}

interface SubLossType {
    name: string;
    icon: string;
}

enum UploadState {
    None = 0,
    ChooseType = 1,
    Upload = 2,
    Describe = 3,
    Complete = 4
}

@Component({
  selector: 'add-loss-legacy',
  templateUrl: './add-loss-legacy.component.html',
  styleUrls: ['./add-loss-legacy.component.scss']
})
export class AddLossLegacyComponent implements OnInit {

    case: CaseModel;
    selectedLossType: LossType;
    selectedSubLossType: SubLossType;
    lossTypes: LossType[];
    form: UntypedFormGroup;
    nameForm: UntypedFormGroup;
    state: UploadState = UploadState.None;
    isLoading: boolean;
    submitted: boolean;
    docsToUpload: DocumentUploadModel[] = [];
    maxFileSize = MAX_FILE_SIZE;
    maxFileCount = MAX_FILES;
    uploadLimit = TOTAL_UPLOAD_LIMIT;
    fileErrors: string[];
    documentGroupId: string = null; // If this is present we can't upload anymore.
    isSettled: boolean;
    nameFormSubmitted: boolean;
    currentMaxFileSizeTotal = 0;

    constructor(private modalA11y: ModalA11yService,
        private fb: UntypedFormBuilder,
        private lossService: LossLegacyService,
        private appInsights: AppInsightsService,
        private route: ActivatedRoute,
        private router: Router) {
            let x = 1;
        }


    get canUploadInfinite(): boolean {
        return this.maxFileCount === Infinity;
    }

    get availableSubLossTypes(): SubLossType[] {
        if (!this.selectedLossType) {
            return [];
        }

        return this.selectedLossType.subTypes;
    }

    get uploadNextButtonDisabled(): boolean {
        return this.isLoading || (this.docsToUpload.length === 0 && this.documentGroupId === null);
    }

    get canRemoveDocuments(): boolean {
        return this.documentGroupId === null && !this.isLoading;
    }

    ngOnInit(): void {
        this.lossTypes = types.lossTypes;
        const currentCase = this.route.snapshot.data.case;
        this.isSettled = currentCase.endOfClaim;

        this.form = this.fb.group({
            'date': [new Date().toISOString().substr(0, 10), [Validators.required]],
            'amount': [null, [Validators.required, Validators.min(0.01)]],
            'notes': ['', [Validators.maxLength(500), Validators.pattern(/^(?:[a-zA-Z0-9\s\&()£?@%\-,:./\\]+)?$/)]]
        });

        this.nameForm = this.fb.group({
            'fileName': ['', [Validators.maxLength(50), Validators.pattern(/^(?:[a-zA-Z0-9_\s]+)?$/)]]
        });

        this.lossService.addLossStarted.subscribe((request: AddLossRequest) => {
            this.reset();
            this.case = request.case;
            this.state = UploadState.ChooseType;
            this.appInsights.logComplexEvent('Add loss started', { case: request.case.reference });
            this.modalA11y.open(this);
        });

        this.lossService.addLossCancelled.subscribe(() => {
            this.appInsights.logComplexEvent('Add loss cancelled', { case: this.case.reference });
            this.reset();
            this.modalA11y.close(this);
        });
    }

    closeAndGoToFaqs() {
        this.close();
        const currentCase = this.route.snapshot.data.case;
        this.router.navigate(['/portal', currentCase.reference, 'faqs'], { queryParams: { q: 'What is a loss?' } });
    }

    setLossType(type: LossType) {
        if (this.selectedLossType !== type) {
            this.selectedSubLossType = null;
        }
        this.selectedLossType = type;
        this.appInsights.logComplexEvent('Loss type selected', { case: this.case.reference, type: type.name });
    }

    addAnotherLoss() {
        this.lossService.startAddLoss(this.case);
    }

    setSubLossType(type: SubLossType) {
        if (this.selectedSubLossType === type) {
            this.selectedSubLossType = null;
        } else {
            this.selectedSubLossType = type;
            this.appInsights.logComplexEvent('Subloss type selected', { case: this.case.reference, sublossType: type.name });
        }
    }

    back() {
        switch (this.state) {
            case UploadState.Describe: {
                this.state = UploadState.Upload;
                break;
            }
            case UploadState.Upload: {
                this.state = UploadState.ChooseType;
                break;
            }
            case UploadState.ChooseType: {
                this.state = UploadState.None;
                break;
            }
            default:
                this.state = UploadState.None;
                break;
        }
    }

    close() {
        if (this.isLoading) {
            return;
        }
        this.lossService.cancel(this.case);
        this.reset();
    }

    submit(form: UntypedFormGroup) {
        this.submitted = true;
        if (form.invalid) {
            return;
        }
        form.disable();
        this.isLoading = true;

        this.fileErrors = [];

        this.lossService.commit(
            this.case,
            this.documentGroupId,
            this.selectedLossType.name,
            this.selectedSubLossType.name,
            new Date(form.value.date),
            form.value.amount,
            form.value.notes).subscribe(() => {
                this.appInsights.logComplexEvent('Loss committed', { case: this.case.reference, groupId: this.documentGroupId });
                this.isLoading = false;
                form.enable();
                this.state = UploadState.Complete;
            }, () => {
                this.fileErrors = ["An error occurred submitting your loss. Please try again"];
                this.isLoading = false;
                form.enable();
            });
    }

    reset() {
        this.submitted = false;
        this.form.setValue({ date: new Date(), amount: 0, notes: null });
        this.case = null;
        this.state = UploadState.None;
        this.docsToUpload = [];
        this.fileErrors = [];
        this.documentGroupId = null;
        this.form = this.fb.group({
            'date': [new Date().toISOString().substr(0, 10), [Validators.required]],
            'amount': [null, [Validators.required, Validators.min(0.01)]],
            'notes': ['', [Validators.maxLength(500), Validators.pattern(/^(?:[a-zA-Z0-9\s\&()£?@%\-,:./\\]+)?$/)]]
        });
        this.nameForm = this.fb.group({
            'fileName': ['', [Validators.maxLength(50), Validators.pattern(/^(?:[a-zA-Z0-9_\s]+)?$/)]]
        })
        this.selectedLossType = null;
        this.selectedSubLossType = null;
        this.currentMaxFileSizeTotal = 0;
    }

    submitDocumentSelection(fileInput: HTMLInputElement) {
        fileInput.click();
    }

    removeFile(doc: DocumentUploadModel) {
        this.docsToUpload.splice(this.docsToUpload.indexOf(doc), 1);
        this.currentMaxFileSizeTotal -= doc.size;
    }

    evidenceFilesChanged(event: InputEvent) {

        const newFiles: FileList = (event.target as any).files;

        this.appInsights.logComplexEvent('Loss files selected', { case: this.case.reference }, { fileCount: newFiles.length });

        this.fileErrors = [];

        Array.from(newFiles).forEach(file => {

            this.currentMaxFileSizeTotal += file.size;

            if (this.currentMaxFileSizeTotal > MAX_FILE_SIZE_TOTAL){
                this.currentMaxFileSizeTotal -= file.size;
                this.fileErrors
                .push(`File named ${file.name} wasn't added because it would exceed the maximum upload total of ${this.uploadLimit}Mb`);
                return;
            }


            if (this.docsToUpload.length === this.maxFileCount) {
                this.currentMaxFileSizeTotal -= file.size;
                this.fileErrors.push(`File named ${file.name} wasn't added because you can only add ${this.maxFileCount} per loss`);
                return;
            }

            const fileExtension: string = file.name.substr(file.name.lastIndexOf('.') + 1);

            // validate the file extension
            if (!AppUtilities.isTypeOfEnum(fileExtension.toUpperCase(), DocumentMimeType)) {
                this.currentMaxFileSizeTotal -= file.size;
                this.fileErrors.push(`File named ${file.name} wasn't added because it isn't a valid file type`);
                this.appInsights.logComplexEvent('File not added', { case: this.case.reference, reason: this.fileErrors[this.fileErrors.length - 1] });
                return;
            }

            if (file.size > this.maxFileSize) {
                this.currentMaxFileSizeTotal -= file.size;
                this.fileErrors.push(`File named ${file.name} wasn't added because exceeds the maximum file size of ${this.maxFileSize / 1024 / 1024}mb`);
                this.appInsights.logComplexEvent('File not added', { case: this.case.reference, reason: this.fileErrors[this.fileErrors.length - 1] });
                return;
            }

            const documentUpload: DocumentUploadModel = {
                file: file,
                isValid: true,
                newFileName: file.name,
                originalFileName: file.name,
                size: file.size,
                type: "losses",
                displayType: "Losses",
                mimeType: null,
                contentBase64: null
            };

            this.docsToUpload.push(documentUpload);
        });
    }

    uploadSubmit(form: UntypedFormGroup) {

        this.fileErrors = [];

        if (this.documentGroupId) { // Already uploaded.
            this.state = UploadState.Describe;
            return;
        }

        this.nameFormSubmitted = true;

        if (form.invalid) {
            return;
        }

        this.isLoading = true;

        form.disable();

        const formData = new FormData();

        let count = 1;

        for (const documentUpload of this.docsToUpload) {

            const originalFilenameExtension = documentUpload.originalFileName.split('.').pop();

            const fileName = form.value.fileName.length === 0 ? documentUpload.newFileName : `${form.value.fileName}-${count}.${originalFilenameExtension}`;

            formData.append(fileName, documentUpload.file);

            count++;
        }

        this.lossService.upload(this.case, formData).subscribe(result => {
            this.appInsights.logComplexEvent('Loss files uploaded', { case: this.case.reference, groupId: result }, { fileCount: count });
            this.documentGroupId = result;
            this.isLoading = false;
            this.state = UploadState.Describe;
        }, () => {
            this.isLoading = false;
                this.fileErrors.push("An error occurred uploading your documents");
                form.enable();
        });
    }
}
